// Because Twig :)
Vue.config.delimiters = ['{=', '}'];
Vue.config.unsafeDelimiters = ['{!!', '!!}'];

// Helpers
var _;

function rand(from, to) {
	return Math.floor(Math.random() * to) + from;
}

function easeInOutQuad( t, b, c, d ) {
	t /= d / 2;
	if ( t < 1 ) return c / 2 * t * t + b;
	t--;
	return -c / 2 * ( t * ( t -2 ) - 1 ) + b;
}

function ScrollTo (endPos) {
	var startPosition = (document.documentElement.scrollTop || document.body.scrollTop),
		endPosition = endPos || 0,
		startTime = null;

	function loop (time) {
		if ( !startTime )
			startTime = time;

		var timeSoFar = time - startTime;
		var easedPosition = easeInOutQuad(timeSoFar, startPosition, endPosition - startPosition, 100);

		window.scrollTo(0, easedPosition);

		if( timeSoFar < 1000 )
			requestAnimationFrame(loop);
	}

	requestAnimationFrame(loop);
}

var CSRF = {
	name: document.getElementById('csrf').getElementsByTagName('input')[0].name,
	value:document.getElementById('csrf').getElementsByTagName('input')[0].value
};

function post(url, data, success, error, noAuth) {
	router.app.isTransitioning++;
	var request = new XMLHttpRequest();
	request.open('POST', baseUrl + 'api/' + url, true);
	request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
	if (!noAuth) request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));

	request.onload = function() {
		router.app.isTransitioning--;
		var data = JSON.parse(request.responseText);
		if (request.status >= 200 && request.status < 400 && (data === true || !('error' in data) && !('errors' in data))) {
			success(data);
		} else {
			if ('error' in data && (data.error === 'jwt-error' || data.error === 'jwt-expired')) {
				doLogout();
			} else {
				error(data);
			}
		}
	};

	request.onerror = function () {
		router.app.isTransitioning--;
		error();
	};

	var d = CSRF.name + '=' + CSRF.value;
	data.forEach(function (el) {
		d += '&' + el[0] + '=' + encodeURI(el[1]);
	});
	request.send(d);
}

function get(url, success, error, noAuth) {
	router.app.isTransitioning++;
	var request = new XMLHttpRequest();
	if (url[0] === '/') url = url.substring(1);
	else url = 'api/' + url;
	request.open('GET', baseUrl + url, true);
	if (!noAuth) request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));

	request.onload = function() {
		router.app.isTransitioning--;
		var data = JSON.parse(request.responseText);
		if (request.status >= 200 && request.status < 400 && !('error' in data)) {
			success(data);
		} else {
			if ('error' in data && (data.error === 'jwt-error' || data.error === 'jwt-expired')) {
				doLogout();
			} else {
				error(data);
			}
		}
	};

	request.onerror = function () {
		router.app.isTransitioning--;
		error();
	};

	request.send();
}

var defaultTitle = document.title + '';

function setTitle(title) {
	if (title && title !== ' ') document.title = title + ' - ' + defaultTitle;
	else document.title = defaultTitle;
}

function setImage(url) {
	router.app.customImage = url;
}

// App
var App = Vue.extend({
		data: function () {
			return {
				isLoggedIn: false,
				navOpen: false,
				isTransitioning: 0,
				search: '',
				user: {},
				college: '',
				searchResults: [],
				customImage: ''
			};
		},
		methods: {
			toggleNav: function (e) {
				e.preventDefault();
				this.navOpen = !this.navOpen;
			},
			clearSearch: function () {
				this.search = '';
			},
			logout: function() {
				doLogout();
			},
			doSearch: function () {
				if (!this.search) {
					this.searchResults = [];
					return;
				}

				var self = this;

				get('search.json?q=' + this.search, function (data) {
					self.searchResults = data.data;
				}, function (err) {
					console.error(err);
				});
			}
		}
	}),
	router = new VueRouter({
		history: true,
		saveScrollPosition: false,
		root: '/'
	});

// Login / Logout
function checkLogin() {
	// TODO: Validate token
	router.app.user = JSON.parse(localStorage.getItem('user'));
	if (router.app.user && router.app.user.college)
		router.app.college = router.app.user.college;
	updateBaseImage();
	return !!(localStorage.getItem('token') && localStorage.getItem('user'));
}

function doLogout() {
	localStorage.removeItem('token');
	localStorage.removeItem('user');

	router.app.isLoggedIn = false;
	router.app.navOpen = false;
	router.app.search = '';
	router.app.user = {};
	router.app.college = '';
	router.go('/');
}

function updateBaseImage() {
	var validImages = [];
	for (var i = 0; i < genericImages.length; i++) {
		for (var x = 0; x < genericImages[i].categories.length; x++) {
			if (genericImages[i].categories.indexOf(genericImages[i].categories[x]) > -1) {
				validImages.push(genericImages[i].url);
				break;
			}
		}
	}
	document.getElementById('baseImage').style.backgroundImage = 'url('+validImages[rand(0, validImages.length)]+')';
}

// Pages
var index = Vue.extend({
	template: '#index',
	data: function () {
		setImage('');

		return {
			app: router.app
		};
	}
});

var login = Vue.extend({
	template: '#login',
	data: function () {
		setImage('');

		return {
			emailAddress: "",
			password: "",
			error: ""
		};
	},
	methods: {
		login: function () {
			var self = this;

			if (this.emailAddress && this.password) {
				post('login', [
					['loginName', this.emailAddress],
					['password', this.password]
				], function (data) {
					localStorage.setItem('token', data.jwt);
					localStorage.setItem('user', JSON.stringify(data.user));

					router.app.isLoggedIn = true;
					router.app.user = data.user;
					router.go('/');
					updateBaseImage();
				}, function (data) {
					self.error = data.error;
				});
			}
		}
	}
});

var register = Vue.extend({
	'template': '#register',
	data: function () {
		setImage('');

		return {
			studentId: "", // Username
			emailAddress: "",
			firstName: "",
			lastName: "",
			educationLevel: "",
			password: "",
			college: "",
			colleges: [],
			error: ""
		};
	},
	route: {
		data: function (transition) {
			get('colleges.json', function (validColleges) {
				var isValid = false, id;
				validColleges = validColleges.data;

				if (!transition.to.params.college) {
					transition.next({
						colleges: validColleges
					});
					return;
				}

				for (var i = 0; i < validColleges.length; i++) {
					if (validColleges[i].slug.toLowerCase() === transition.to.params.college.toLowerCase()) {
						isValid = true;
						id = validColleges[i].id;
						break;
					}
				}

				if (!isValid) {
					transition.abort();
					router.go('/register');
					return;
				}

				router.app.college = [id];
				transition.next({
					college: id
				});
			}, function (err) {
				console.error('Error getting colleges', err);
			});
		}
	},
	methods: {
		register: function () {
			if (
				!this.emailAddress ||
				!this.firstName ||
				!this.lastName ||
				!this.educationLevel ||
				this.educationLevel === "" ||
				!this.password ||
				!this.college ||
				this.college === ""
			) {
				this.error = "Please complete all required fields";
				return;
			}

			var username = this.studentId || this.emailAddress,
				self = this;

			this.error = "";
			
			post('register', [
				['act', 'users/saveUser'],
				['username', username],
				['email', this.emailAddress],
				['firstName', this.firstName],
				['lastName', this.lastName],
				['password', this.password],
				['groups', 1],
				['fields[educationLevel]', this.educationLevel],
				['fields[college]', this.college]
			], function () {
				router.app.college = [self.college];
				router.go('/login');
			}, function (err) {
				if (err.errors) self.error = err.errors[Object.keys(err.errors)[0]][0];
				else console.error(err);
			});
		}
	}
});

var forgot = Vue.extend({
	template: '#forgot',
	data: function () {
		setImage('');

		return {
			emailAddress: "",
			message: ''
		};
	},
	methods: {
		forgot: function () {
			var self = this;

			if (this.emailAddress) {
				post('reset', [
					['act', 'users/forgotPassword'],
					['loginName', this.emailAddress]
				], function () {
					self.message = 'Reset link sent.';
				}, function (data) {
					self.error = data.error;
				});
			}
		}
	}
});

var CATEGORIES = {};
var questionsCategoryIndex = Vue.extend({
	template: '#categoryIndex',
	data: function () {
		setImage('');

		return {
			title: 'Discover Q&A',
			categories: CATEGORIES.question,
			catRouteName: 'questionCat'
		};
	}
});
var resourcesCategoryIndex = Vue.extend({
	template: '#categoryIndex',
	data: function () {
		setImage('');

		return {
			title: 'Explore Resources',
			categories: CATEGORIES.resource,
			catRouteName: 'resourceCat'
		};
	}
});
function loadCategories(resolve, component, type) {
	if (!type) type = 'question';
	get('categories.json?type=' + type, function (data) {
		CATEGORIES[type] = data.data;
		if (resolve)
			resolve(component);
	}, function (err) {
		console.error('Error loading categories', err);
	});
}
function getCatBySlug(slug, callback, type) {
	if (!type) type = 'question';
	function run(slug) {
		CATEGORIES[type].forEach(function (c) {
			if (c.slug === slug)
				callback(c);
		});
	}

	if (!(type in CATEGORIES)) {
		loadCategories(function () {
			run(slug);
		}, null, type);
	} else {
		run(slug);
	}
}

var questionsCategory = Vue.extend({
	template: '#questionsCategory',
	data: function () {
		return {
			category: {},
			questions: [],
			page: { current: 0, total: 0 }
		};
	},
	route: {
		data: function (transition) {
			getCatBySlug(transition.to.params.slug, function (c) {
				get('questions-' + c.id +'.json', function (data) {
					transition.next({
						category: c,
						questions: data.data,
						page: {
							current: data.meta.pagination.current_page,
							total: data.meta.pagination.total_pages
						}
					});
					setTitle(c.title);
					setImage(c.image);
				}, function (err) {
					console.error('Error getting questions', err);
				});
			});
		}
	}
});
var resourcesCategory = Vue.extend({
	template: '#resourcesCategory',
	data: function () {
		return {
			category: {},
			resources: [],
			page: { current: 0, total: 0 }
		};
	},
	route: {
		data: function (transition) {
			getCatBySlug(transition.to.params.slug, function (c) {
				get('resources-' + c.id +'.json', function (data) {
					transition.next({
						category: c,
						resources: data.data,
						page: {
							current: data.meta.pagination.current_page,
							total: data.meta.pagination.total_pages
						}
					});
					setTitle(c.title);
					setImage(c.image);
				}, function (err) {
					console.error('Error getting questions', err);
				});
			}, 'resource');
		}
	}
});

var question = Vue.extend({
	template: '#question',
	data: function () {
		return {
			app: router.app,
			id: -1,
			author: '',
			postDate: '',
			slug: '',
			title: '',
			text: '',
			category: {},
			answers: [],
			page: { current: 0, total: 0 },
			replyOpen: false,
			replyAnswer: ''
		};
	},
	route: {
		data: function (transition) {
			getCatBySlug(transition.to.params.catSlug, function (c) {
				get('question/' + transition.to.params.slug + '.json', function (data) {
					data.category = c;
					get('answers/' + data.id + '.json', function (ans) {
						data.answers = ans.data;
						data.page = {
							current: ans.meta.pagination.current_page,
							total: ans.meta.pagination.total_pages
						};
						transition.next(data);
						setTitle(data.title + ' - ' + data.category.title);
						setImage(data.category.image);
					}, function (err) {
						console.error('Error getting answers', err);
					});
				}, function (err) {
					console.error('Unable to load question', err);
				});
			});
		}
	},
	methods: {
		submitAnswer: function () {
			var self = this;

			post('answer', [
				['act', 'comments/save'],
				['elementId', this.id],
				['fields[comment]', this.replyAnswer]
			], function () {
				self.replyOpen = false;
				self.replyAnswer = '';

				get('answers/' + self.id + '.json', function (ans) {
					self.answers = ans.data;
					self.page = {
						current: ans.meta.pagination.current_page,
						total: ans.meta.pagination.total_pages
					};
				}, function (err) {
					console.error('Error getting answers', err);
				});
			}, function (err) {
				console.error(err);
			});
		},
		submitComment: function (answer) {
			post('answer', [
				['act', 'comments/save'],
				['elementId', answer.id],
				['fields[comment]', answer.replyComment]
			], function () {
				answer.replyOpen = false;

				answer.comments.push({
					author: router.app.user.firstName,
					postDate: window.today,
					text: answer.replyComment
				});

				answer.replyComment = '';
			}, function (err) {
				console.error('Unable to save comment', err);
			});
		},
		grow: function (e) {
			e.target.style.height = "";
			e.target.style.height = e.target.scrollHeight + 4 + "px";
		},
		vote: function (dir, answer) {
			function showError(message) {
				answer.error = message;
				if (answer.to) clearTimeout(answer.to);
				answer.to = setTimeout(function () { answer.error = ''; }, 5000);
			}

			if (!answer.canUpVote && !answer.canDownVote) {
				showError('You can\'t vote on this answer');
				return;
			}

			post('vote?id=' + answer.id, [
				['act', 'comments/' + dir + 'voteComment']
			], function (data) {
				answer.votes = data.votes;
				if (dir === 'up') {
					answer.canUpVote = false;
					answer.canDownVote = true;
				}
				if (dir === 'down') {
					answer.canUpVote = true;
					answer.canDownVote = false;
				}
			}, function (err) {
				showError(err.error);
			});
		},
		report: function (answer) {
			function showError(message) {
				answer.error = message;
				if (answer.to) clearTimeout(answer.to);
				answer.to = setTimeout(function () { answer.error = ''; }, 5000);
			}

			post('answer?id=' + answer.id, [
				['act', 'comments/flagComment']
			], function () {
				showError('Answer reported');
			}, function (err) {
				showError(err.error);
			});
		}
	}
});

var resource = Vue.extend({
	template: '#resource',
	data: function () {
		return {
			title: '',
			postDate: '',
			text: '',
			category: {}
		};
	},
	route: {
		data: function (transition) {
			getCatBySlug(transition.to.params.catSlug, function (c) {
				get('resource/' + transition.to.params.slug + '.json', function (data) {
					data.category = c;
					transition.next(data);
					setTitle(data.title + ' - ' + data.category.title);
					setImage(data.category.image);
				}, function (err) {
					console.error('Unable to load question', err);
				});
			}, 'resource');
		}
	}
});

var ask = Vue.extend({
	template: '#ask',
	data: function () {
		setImage('');

		return {
			question: '',
			categories: CATEGORIES.question,
			category: { id: CATEGORIES.question[0].id, slug: CATEGORIES.question[0].slug },
			error: '',
			text: ''
		};
	},
	methods: {
		ask: function () {
			var self = this;
			this.error = '';

			post('ask', [
				['sectionId', 3],
				['enabled', 1],
				['act', 'entries/saveEntry'],
				['title', this.question],
				['fields[questionCategory]', this.category.id],
				['fields[text]', this.text],
				['fields[college]', router.app.college],
				['fields[educationLevel]', router.app.user.educationLevel]
			], function (data) {
				get('question-' + data.id + '.json', function (data) {
					router.go({
						name: 'question',
						params: {
							catSlug: self.category.slug,
							slug: data.data[0].slug
						}
					});
				}, function (err) {
					console.error('Error get new question slug', err);
				});
			}, function (error) {
				if (error.errors)
					self.error = error.errors[Object.keys(error.errors)[0]][0];
			});
		},
		grow: function (e) {
			e.target.style.height = "";
			e.target.style.height = e.target.scrollHeight + 4 + "px";
		},
		search: function () {
			router.app.search = this.question;
		}
	}
});

var contact = Vue.extend({
	template: '#contact',
	data: function () {
		setImage('');

		var subjects = CATEGORIES.question;
		subjects.push({
			title: 'Other'
		});

		return {
			subjects: subjects,
			subject: '',
			error: '',
			message: '',
			success: false
		};
	},
	methods: {
		send: function () {
			var self = this;
			this.success = false;
			this.error = '';

			post('ask', [
				['act', 'contactForm/sendMessage'],
				['fromName', router.app.user.firstName + ' ' + router.app.user.lastName],
				['fromEmail', router.app.user.email],
				['subject', this.subject],
				['message[body]', this.message],
				['message[From]', router.app.user.firstName + ' ' + router.app.user.lastName],
				['message[Reply-To]', router.app.user.email]
			], function (data) {
				// self.subject = '';
				self.error = '';
				self.message = '';
				self.success = true;
			}, function (error) {
				if (error.errors)
					self.error = error.errors[Object.keys(error.errors)[0]][0];
			});
		},
		grow: function (e) {
			e.target.style.height = "";
			e.target.style.height = e.target.scrollHeight + 4 + "px";
		}
	}
});

var eventsIndex = Vue.extend({
	template: '#eventsIndex',
	data: function () {
		return {
			events: []
		};
	},
	route: {
		data: function () {
			var self = this;

			get('events.json', function (data) {
				self.events = data.data;
			}, function (err) {
				console.error(err);
			});
		}
	}
});

var event = Vue.extend({
	template: '#event',
	data: function () {
		return {
			title: '',
			startDate: '',
			startTime: '',
			endDate: '',
			endTime: '',
			image: '',
			textContent: '',
			map: '',
			link: '',
		};
	},
	route: {
		data: function (transition) {
			get('event/' + transition.to.params.slug + '.json', function (data) {
				setTitle(data.title);
				transition.next(data);
			}, function (err) {
				console.error(err);
			});
		}
	}
});

// ROUTES
router.map({
	'/' : { component: index, title: ' ' },
	'/login': { component: login, title: 'Login' },
	'/register/:college': { name: 'registerCollege', component: register, title: 'Register' },
	'/register': { name: 'register', component: register, title: 'Register' },
	'/forgot': { component: forgot, title: 'Forgotten Password' },
	'/questions': { component: function (r) { loadCategories(r, questionsCategoryIndex); }, title: 'Questions' },
	'/questions/:slug': { name: 'questionCat', component: questionsCategory },
	'/questions/:catSlug/:slug': { name: 'question', component: question },
	'/resources': { component: function (r) { loadCategories(r, resourcesCategoryIndex, 'resource'); }, title: 'Resources' },
	'/resources/:slug': { name: 'resourceCat', component: resourcesCategory },
	'/resources/:catSlug/:slug': { name: 'resource', component: resource },
	'/events': { name: 'events', component: eventsIndex, title: 'Events' },
	'/events/:slug': { name: 'event', component: event },
	'/ask': { component: function (r) { loadCategories(r, ask); }, title: 'Ask' },
	'/contact': { component: function (r) { loadCategories(r, contact); }, title: 'Contact' },
});

// Router Events
router.beforeEach(function (transition) {
	router.app.isLoggedIn = checkLogin();
	ScrollTo();

	if (transition.to.matched.length/* && transition.to.matched[0].handler.title*/) {
		setTitle(transition.to.matched[0].handler.title);
	}

	if ((transition.to.path === '/login' ||
		transition.to.path.indexOf('/register') > -1 ||
		transition.to.path === '/forgot') &&
		router.app.isLoggedIn) {
		transition.abort();
		router.go('/');
	} else if (transition.to.path !== '/' &&
		transition.to.path !== '/login' &&
		transition.to.path.indexOf('/register') === -1 &&
		transition.to.path !== '/forgot' &&
		!router.app.isLoggedIn) {
		transition.abort();
		router.go('/login');
	} else {
		router.app.navOpen = false;
		router.app.isTransitioning++;
		transition.next();
	}
});

router.afterEach(function () {
	router.app.isTransitioning--;
});

// START
router.start(App, 'body', function () {
	router.app.$watch('search', function (query) {
		router.app.doSearch();
	});
});


// Bubbles!
var bubbles = document.getElementById('bubbles');
function blowBubble() {
	var bubble = document.createElement('div'),
		size = rand(40, 300),
		dur = rand(20, 50);
	bubble.className = 'bubble';
	bubble.style.width = size + 'px';
	bubble.style.height = size + 'px';
	bubble.style.left = rand(0, 45) + '%';
	bubble.style.transitionDuration = dur + 's';
	bubbles.appendChild(bubble);
	_ = bubble.outerHeight;
	setTimeout(function () {
		bubble.classList.add('float');
	}, 100);
	setTimeout(function () {
		bubble.parentNode.removeChild(bubble);
	}, (dur * 1000) + 100);
}
blowBubble();
setInterval(function () {
	blowBubble();
}, 5000);